@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  cursor: pointer;
}

.scrollbar {
  border-color: rgb(51 65 85/30%);
  transition: border-color 0.5s linear;;
}

.scrollbar::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

.scrollbar::-webkit-scrollbar-corner {
  background-color: rgba(0, 0, 0, 0.2);
}

.scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: rgb(226 232 240 / 0);
  border-radius: 10px
}

.dark .scrollbar::-webkit-scrollbar {
  background-color: rgb(39 39 42 / 1);
}

/* .scrollbar:hover {
  border-color: rgba(0, 0, 0, 0.2);
  transition: background-color 300ms;
} */

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* add border to act as background-color */
  border-right-style: inset;
  /* sum viewport dimensions to guarantee border will fill scrollbar */
  border-right-width: calc(100vw + 100vh);
  /* inherit border-color to inherit transitions */
  border-color: inherit;
}
